import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { Grid, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import {
  PartnerSummary,
  PARTNER_PRODUCTS,
  CONFIGURATION,
  PARTNER_CAUSE_AREA_OPTIONS,
  DATA_SHARING_REPORT_TYPES_EXPLANATIONS,
} from 'lib/models/partner'
import { strToBool } from 'utils/helpers'

import Table from 'components/table'
import Row from './row'

const StyledPreviewImage = styled.img`
  width: 200px;
`
type TableProps = PartnerSummary & {
  logo?: Blob | MediaSource
  giftPromptImage?: Blob | MediaSource
}

const StyledGridItem = styled(Grid.Item)`
  word-wrap: break-word;
`

const StyledList = styled.ul`
  list-style: disc;
  padding-left: ${GTR.M};
  margin-bottom: 0;
`

const StyledReactMarkdown = styled(ReactMarkdown)`
  overflow-wrap: break-word;
`

const names = {
  [PARTNER_PRODUCTS.ONLINE_WILL]: 'Online',
  [PARTNER_PRODUCTS.TELEPHONE_WILL]: 'Telephone',
}

const PartnerSummaryTable = ({
  values,
}: {
  values: TableProps
}): React.ReactElement => {
  const { charity, organisation, products, onlineWillConfiguration, urls } =
    values
  const productNames = products.map((product) => names[product]).join(' & ')
  let causeAreaLabel
  if (values.causeArea) {
    causeAreaLabel = PARTNER_CAUSE_AREA_OPTIONS.find(
      (option) => option.value === values.causeArea
    )?.label
  }
  const dataSharingReportTypeExplanation =
    DATA_SHARING_REPORT_TYPES_EXPLANATIONS.find(
      (option) => option.value === values.dataSharingReportType
    )?.label

  return (
    <Table striped>
      <Row
        label="Legal name"
        value={charity?.legalName || organisation?.name || ''}
      />
      {charity && (
        <>
          <Row label="Brand name (optional)" value={charity.commonName} />
          <Row label="Charity number" value={charity.number} />
        </>
      )}
      {organisation && (
        <>
          <Row label="Company number" value={organisation.number} />
          <Row label="Registered address" value={organisation.address} />
        </>
      )}
      <Row label="UTM source" value={values.utmSource} />
      {organisation && (
        <Row
          label="Charitable status"
          value={
            strToBool(organisation.hasExemptCharitableStatus) ? 'Yes' : 'No'
          }
        />
      )}
      <Row label="HubSpot URL" value={values.hubspotUrl} />
      {causeAreaLabel && <Row label="Cause area" value={causeAreaLabel} />}
      <Row
        label="Monthly reports"
        value={`${dataSharingReportTypeExplanation}`}
      />
      <Row label="Product setup" value={productNames || 'None'} />
      {products.includes(PARTNER_PRODUCTS.ONLINE_WILL) && (
        <>
          <Row
            label="Online journey options"
            value={
              <StyledList>
                <li>
                  {onlineWillConfiguration.includes(CONFIGURATION.A11Y_CHECKS)
                    ? 'With '
                    : 'No '}
                  accessibility checks
                </li>
                <li>
                  {onlineWillConfiguration.includes(
                    CONFIGURATION.MAILCHIMP_BESPOKE
                  )
                    ? 'With '
                    : 'No '}
                  bespoke Mailchimp journey
                </li>
              </StyledList>
            }
          />
        </>
      )}
      {/* Shown when creating or updating the logo */}
      {values.logo ? (
        <Row
          label="New logo"
          value={
            <StyledPreviewImage
              src={URL.createObjectURL(values.logo)}
              alt="Logo preview"
            />
          }
        />
      ) : (
        /* Shown when viewing the existing logo that is in the DB */
        <Row
          label="Logo"
          value={<StyledPreviewImage src={values.logoUrl} alt="Logo" />}
        />
      )}
      <Row
        label="Gift prompt description"
        value={
          <StyledReactMarkdown>
            {values.giftPromptDescription}
          </StyledReactMarkdown>
        }
      />
      {/* Shown when creating or updating the giftPromptImage */}
      {values.giftPromptImage ? (
        <Row
          label="New gift prompt image"
          value={
            <StyledPreviewImage
              src={URL.createObjectURL(values.giftPromptImage)}
              alt="Gift prompt image preview"
            />
          }
        />
      ) : (
        /* Shown when viewing the existing giftPromptImage that is in the DB */
        <Row
          label="Gift prompt image"
          value={
            values.giftPromptImageUrl && (
              <StyledPreviewImage
                src={values.giftPromptImageUrl}
                alt="Gift prompt image preview"
              />
            )
          }
        />
      )}

      <Table.Row gridTemplateCols="6fr">
        <Grid gap={0}>
          <Grid.Item span={4}>
            <strong>AI marketing assistant URLs</strong>
          </Grid.Item>
          <StyledGridItem span={8}>
            {urls?.length > 0
              ? urls.map((url) => {
                  return <P key={url}>{url}</P>
                })
              : 'Not set up'}
          </StyledGridItem>
        </Grid>
      </Table.Row>
    </Table>
  )
}

export default PartnerSummaryTable
