import isNull from 'lodash/isNull'
import mapValues from 'lodash/mapValues'
import identity from 'lodash/identity'
import isPlainObject from 'lodash/isPlainObject'
import isBoolean from 'lodash/isBoolean'
import lowerCase from 'lodash/lowerCase'
import upperFirst from 'lodash/upperFirst'
import upperCase from 'lodash/upperCase'
import isFinite from 'lodash/isFinite'
import moment from 'moment'
import { customAlphabet } from 'nanoid'
import sortBy from 'lodash/sortBy'

import ENV from 'config/environment'
import { Address } from 'api/generated/graphql'
import { Constants } from 'lib/models/constants'
import { Contact } from 'lib/models/contact'
import { Event } from 'lib/models/event'
import { LeadAttributes } from 'lib/models/lead'
import { CommentAttributes } from 'lib/models/comment'
import { Invoice, InvoiceLineItem } from 'lib/models/invoice'
import { AdminUser } from 'lib/models/admin-user'
import { Celebrant } from 'lib/models/celebrant'
import { Country } from 'lib/models/country'
import { AdminUserSelectOptions } from 'routes/case-list/types'
import { getDefinedTimeslot } from 'routes/lead/helpers'

import {
  COLLECT,
  DELIVER,
  FAST_TRACK_DELIVER,
  FUNERAL_LEAD_STATUS,
  INVOICE_LINE_ITEMS,
  LEAD_PARTNER_TYPES,
  LEAD_STATUS,
  SCATTER,
} from './enums'

const FORMATS = {
  H: { long: ' hours ', short: 'h ' },
  M: { long: ' minutes ', short: 'm ' },
  S: { long: ' seconds', short: 's' },
}
export const formatSeconds = (
  d: number,
  format: 'short' | 'long' = 'short'
): string | '' => {
  d = Number(d)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  const hDisplay = h > 0 ? h + FORMATS.H[format] : ''
  const mDisplay = m > 0 ? m + FORMATS.M[format] : ''
  const sDisplay = s > 0 ? s + FORMATS.S[format] : ''
  return hDisplay + mDisplay + sDisplay
}

const exactStringToBooleanMapping = {
  true: true,
  false: false,
}

export type strToBoolProp = 'true' | 'false' | undefined | null | boolean | ''

export function isStrBoolProp(flag: strToBoolProp): flag is strToBoolProp {
  return flag === 'true' || flag === 'false'
}

export function strToBool(str: strToBoolProp): boolean | undefined {
  if (isBoolean(str)) return str
  if (!str) return
  return exactStringToBooleanMapping[str]
}

export function boolToStr(
  bool: boolean | undefined | null
): 'true' | 'false' | undefined | null {
  if (bool === null || bool === undefined) return bool
  const boolAsString = bool.toString() as 'true' | 'false'
  return boolAsString
}

/** Ensures that a passed input returns as a number or null */
export const ensureNumberOrNull = (
  input: string | number | undefined
): number | null => {
  // return straight away if it is already a number
  if (typeof input === 'number') return input

  const value = typeof input === 'string' ? removeCommas(input) : input
  const stringAsNumber = parseFloat(value as string)
  if (isFinite(stringAsNumber)) return stringAsNumber
  return null
}

export const strToStrOrNull = (str: string): string | null => {
  if (str === '') return null

  return str
}

const isDateTime = (key: string): boolean =>
  ['factFindCallAt', 'scheduledNextCallAt'].includes(key)

//We want to add types to formatValuesForFormik but we're going to do that in a separate PR.

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export function formatValuesForFormik(values: any): any {
  return mapValues(values, (value, key) => {
    if (isPlainObject(value)) return formatValuesForFormik(value)
    if (isNull(value)) return ''
    if (isBoolean(value)) return boolToStr(value)

    if (isDateTime(key)) {
      return value ? moment.utc(value).local().format('YYYY-MM-DDTHH:mm') : ''
    }

    return value
  })
}

export function findEvent(events: Event[], name: string): Event | undefined {
  return events.find((event) => {
    if (event.attributes) {
      return event.attributes.name === name
    } else {
      return event.name === name
    }
  })
}

type Task = {
  attributes?: {
    coreTask: string
  }
  coreTask?: string
}

export const findTask = (tasks: Task[], coreTask: string): Task | undefined => {
  return tasks.find((task) => {
    if (task.attributes) {
      return task.attributes.coreTask === coreTask
    } else {
      return task.coreTask === coreTask
    }
  })
}

export function getFullName(contact?: Contact): string | null {
  if (!contact) return null
  const { firstName, lastName } = contact
  return [firstName, lastName].filter(identity).join(' ')
}

export const getFullNameWithPreferredName = (
  contact?: Contact
): null | string => {
  if (!contact) return null
  const { firstName, lastName, preferredName } = contact

  if (!firstName && !lastName && !!preferredName) return preferredName

  const formattedPreferredName = preferredName ? ` (${preferredName})` : ''

  return `${getFullName(contact)}${formattedPreferredName}`
}

type AircallContact = {
  first_name: string
  last_name: string
}

export const getAircallContactName = (
  contact?: AircallContact
): null | string => {
  if (!contact) return null
  return [contact.first_name, contact.last_name].filter(identity).join(' ')
}

export const yesterday = (): moment.MomentInput => moment().add(-1, 'day')

export const dateIsYesterday = (date: string): boolean => {
  return moment(date).isSame(yesterday(), 'day')
}

export const dateIsToday = ({
  date,
  treatAnyTimeInPastAsOverdue = false,
}: {
  date: string
  treatAnyTimeInPastAsOverdue?: boolean
}): boolean => {
  const isToday = moment(date).isSame(moment(), 'day')
  return treatAnyTimeInPastAsOverdue
    ? isToday && moment().isBefore(moment(date))
    : isToday
}

export const tomorrow = (): moment.MomentInput => {
  return moment().add(1, 'day')
}

export const dateIsTomorrow = (date: string): boolean => {
  return moment(date).isSame(tomorrow(), 'day')
}

export const formatDateTimeToUTC = (
  dateTime?: Date
): null | moment.MomentInput => {
  if (!dateTime) return null
  return moment(dateTime).utc().format()
}

export const formatDateTimeToString = (
  dateTime: Date | moment.MomentInput,
  { includeTime = true } = {}
): null | moment.MomentInput => {
  if (!dateTime) return null
  return moment
    .utc(dateTime)
    .local()
    .format(includeTime ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD')
}

// Treats a datetime as a local browser date, not a UTC one
// This works when we want to store timezone-less dates from the
// API e.g. 2021-09-18
export const formatLocalDateTimeToString = (
  dateTime: Date | moment.MomentInput,
  { includeTime = true, timeOnly = false } = {}
): null | moment.MomentInput => {
  if (!dateTime) return null
  const date = moment(dateTime)

  if (timeOnly) {
    return date.format('HH:mm')
  }

  return date.format(includeTime ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD')
}

// The DatetimePicker component we use requires a JS date object
// so we construct one using the dateString, or today's date
// Since we don't store any timezone info, it doesn't matter what
// we use as when the time is saved, we extract a timezone-less time string
// from the date object
export const formatTimeToDateTimeString = (timeString: string): string => {
  if (!timeString) {
    return ''
  }

  const formattedDate = moment().format('YYYY-MM-DD')

  return `${formattedDate}T${timeString}`
}

export interface Response {
  id: number
  type: string
  attributes: LeadAttributes | CommentAttributes
}
type FlattenedResponse = (LeadAttributes | CommentAttributes) & {
  id: number
  type: string
}

export const flattenAttributes = (response: Response): FlattenedResponse => {
  return { ...response, ...response.attributes }
}

export const formatToHuman = (value: string): string => {
  return upperFirst(lowerCase(value))
}

export const formatDateToISO = (
  year: number,
  month: number,
  day: number
): string | null => {
  if (!year || !month || !day) return null

  return `${year}-${month}-${day}`
}

export const removeCommas = (string?: string): string | undefined =>
  string?.replace(/,/g, '')

export const findLineItemByDescription = ({
  description,
  invoice,
}: {
  description: string
  invoice?: Invoice
}): InvoiceLineItem | undefined => {
  return invoice?.attributes.lineItems.find(
    (lineItem) => lineItem.description === description
  )
}

export const findDepositInvoice = (
  invoices: Invoice[]
): Invoice | undefined => {
  return invoices.find((invoice) =>
    findLineItemByDescription({
      invoice,
      description: INVOICE_LINE_ITEMS.DEPOSIT,
    })
  )
}

export const findCaseBookedInvoice = (
  invoices: Invoice[]
): Invoice | undefined => {
  return invoices.find((invoice) =>
    findLineItemByDescription({
      invoice,
      description: INVOICE_LINE_ITEMS.SERVICE_FEE,
    })
  )
}

export const getGoogleDriveFileIdFromUrl = (
  string: string
): string | undefined => {
  try {
    const url = new URL(string)
    const isExpectedDomain = url.host.match(/^(drive|docs)\.google\.com$/)
    // eslint-disable-next-line
    // @ts-ignore
    const fileId = url.pathname.match(/\/d\/([a-zA-Z0-9-_]+)(\/|$)/)[1]

    return isExpectedDomain ? fileId : undefined
  } catch {
    return undefined
  }
}

export const generatePaymentReference = (): string => {
  return customAlphabet('2456789BCDFGHJKLMNPQRSTVWXYZ', 14)()
}

export const showPartnerSelect = (partnerType: string | null): boolean =>
  partnerType === LEAD_PARTNER_TYPES.charity ||
  partnerType === LEAD_PARTNER_TYPES.other_partner

export const showUnsuitableReasonSelect = (status: string): boolean =>
  status === LEAD_STATUS.LOST

export const showFuneralBlockedReasonSelect = (status: string): boolean =>
  status === FUNERAL_LEAD_STATUS.BLOCKED

const formatDateString = ({
  date,
  treatAnyTimeInPastAsOverdue = false,
}: {
  date: string
  treatAnyTimeInPastAsOverdue?: boolean
}): string => {
  if (dateIsToday({ date, treatAnyTimeInPastAsOverdue })) return 'today'
  if (dateIsYesterday(date)) return 'yesterday'
  if (dateIsTomorrow(date)) return 'tomorrow'
  if (moment(date).isAfter(moment())) return moment(date).format('D MMM')
  return treatAnyTimeInPastAsOverdue
    ? moment(date).from(moment())
    : moment(date).from(moment().startOf('day'))
}

export const nextTaskDueOnString = (
  date: string | undefined
): string | null => {
  if (!date) return null
  return formatDateString({ date })
}

export const getTimeDescriptionFromTimestamp = (
  date: string | undefined,
  showTime: boolean
) => {
  if (!date) return null
  let timeString = ''
  timeString += formatDateString({
    date,
    treatAnyTimeInPastAsOverdue: true,
  })

  const isCallTimeInPast = ['yesterday', 'ago'].some((word) =>
    timeString.includes(word)
  )

  if (showTime && !isCallTimeInPast) {
    const callTimeslot = getDefinedTimeslot(date)?.label

    timeString += callTimeslot
      ? ` ${callTimeslot}`
      : ` ${moment(date).format('h:mmA')}`
  }

  return timeString
}

export const truncateText = (text: string, characterLimit: number): string => {
  if (text.length <= characterLimit) return text
  return `${text.substring(0, characterLimit)}...`
}

export const formatAdminUserOptions = (
  adminUsers: AdminUser[],
  loggedInAdminUserId?: number
): AdminUserSelectOptions => {
  if (loggedInAdminUserId) {
    adminUsers.sort((user) => (user.id === loggedInAdminUserId ? -1 : 0))
  }
  return adminUsers.map((adminUser) => ({
    label: adminUser.attributes.name,
    value: adminUser.id,
  }))
}

interface CelebrantOption {
  label: string
  value: number
  isDeleted: boolean
}

export const formatCelebrantOptions = (
  celebrants: Celebrant[]
): CelebrantOption[] => {
  const allCelebrantOptions = celebrants.map((celebrant) => ({
    label: celebrant.attributes.name,
    value: celebrant.id,
    isDeleted: celebrant.attributes.isDeleted,
  }))

  const sortedNoCelebrantOptions: CelebrantOption[] = []

  const noCelebrantOptions = [
    'Not answered',
    'No celebrant',
    'Using a different celebrant',
    'Celebrant to be decided',
  ]

  noCelebrantOptions.forEach((noCelebrantOption) => {
    const noCelebrantOptionFound = allCelebrantOptions.find(
      ({ label }) => label === noCelebrantOption
    )

    if (noCelebrantOptionFound)
      sortedNoCelebrantOptions.push(noCelebrantOptionFound)
  })

  const celebrantOptions = allCelebrantOptions.filter(
    (celebrantOption) => !noCelebrantOptions.includes(celebrantOption.label)
  )

  const sortedCelebrantOptions = sortBy(celebrantOptions, ({ label }) => label)

  return [...sortedNoCelebrantOptions, ...sortedCelebrantOptions]
}

export interface CountryOption {
  label: string
  value: number
}

export const formatCountryOptions = (
  countries: Country[] = []
): CountryOption[] => {
  const countryOptions = countries.map((country) => ({
    label: country.attributes.name,
    value: country.id,
  }))

  return sortBy(countryOptions, ({ label }) => label)
}

export const formatProductName = (key: string): string => {
  switch (key) {
    case 'lpa':
      return upperCase(key)

    case 'will':
      return 'Telephone wills'

    default:
      return formatToHuman(key)
  }
}

/** I am giving this type a generic name as ideally it will be used to type other options in future besides trees */
export interface StringValueFormOption {
  label: string
  value: string
}

export const formatAshesInstructionsOptions = ({
  ashesDeliveryPrice,
  ashesFastTrackDeliveryPrice,
}: {
  ashesDeliveryPrice?: number
  ashesFastTrackDeliveryPrice?: number
}): StringValueFormOption[] => {
  const deliverLabel =
    ashesDeliveryPrice !== undefined
      ? `Deliver (${ashesDeliveryPrice > 0 ? `+£${ashesDeliveryPrice}` : '£0'})`
      : 'Deliver'

  const fastTrackOptions = ashesFastTrackDeliveryPrice
    ? [
        {
          value: FAST_TRACK_DELIVER,
          label: `Deliver within five days (+£${ashesFastTrackDeliveryPrice})`,
        },
      ]
    : []

  return [
    { value: DELIVER, label: deliverLabel },
    ...fastTrackOptions,
    { value: SCATTER, label: 'Scatter at crematorium' },
    {
      value: COLLECT,
      label: 'Collect from crematorium',
    },
  ]
}

export const formatTreeName = (name: string): string => {
  return formatToHuman(name.replace('tree_', ''))
}

export const formatTreeOptions = (
  trees: Constants['attributes']['treePrices']
): StringValueFormOption[] => {
  if (!trees) return []
  const treeNames = Object.keys(trees)
  const treeOptions = treeNames.map((name) => ({
    label: `${formatTreeName(name)} (+£${trees[name]})`,
    value: name,
  }))

  return treeOptions
}

/** Sorts urn options so that the card scattering and additional card
 * scattering urns appear together in the list */
export const sortUrnOptions = ({
  urnCurrentOptions,
  urnSpecialOptions,
  urnLegacyOptions,
}: {
  urnCurrentOptions: StringValueFormOption[]
  urnSpecialOptions: StringValueFormOption[]
  urnLegacyOptions: StringValueFormOption[]
}) => {
  const urnCardScatteringOptions: StringValueFormOption[] = []
  urnCurrentOptions = urnCurrentOptions.filter((option) => {
    if (option.value === 'card_scattering') {
      urnCardScatteringOptions.push(option)
      return false
    }
    return true
  })

  urnSpecialOptions = urnSpecialOptions.filter((option) => {
    if (option.value === 'additional_card_scattering') {
      urnCardScatteringOptions.push(option)
      return false
    }
    return true
  })

  return [
    ...urnCardScatteringOptions,
    ...urnCurrentOptions,
    ...urnSpecialOptions,
    ...urnLegacyOptions,
  ]
}
export const formatUrnOptions = (
  urns: Constants['attributes']['urnPrices']
): StringValueFormOption[] => {
  if (!urns) return []

  const urnCurrentNames = Object.keys(urns.current)
  const urnSpecialNames = Object.keys(urns.special)
  const urnLegacyNames = Object.keys(urns.legacy)
  const urnCurrentOptions = urnCurrentNames.map((name) => ({
    label: `${formatToHuman(name)} urn (+£${urns.current[name]})`,
    value: name,
  }))
  const urnSpecialOptions = urnSpecialNames.map((name) => ({
    label: `${formatToHuman(name)} (+£${urns.special[name]})`,
    value: name,
  }))
  const urnLegacyOptions = urnLegacyNames.map((name) => ({
    label: `[Legacy] ${formatToHuman(name)} urn (+£${urns.legacy[name]})`,
    value: name,
  }))

  return sortUrnOptions({
    urnCurrentOptions,
    urnSpecialOptions,
    urnLegacyOptions,
  })
}

export const formatWillSuiteStatus = (status: string): string => {
  return status.replaceAll('_', ' ')
}

export const squareUrlFromTransactionId = (id: string): string => {
  return `${ENV.SQUARE_DASHBOARD_URL}/sales/transactions/${id}`
}

export const squareUrlForTransactionPage = (id: string): string => {
  return `${ENV.SQUARE_DASHBOARD_URL}/sales/transactions/${id}`
}

export const squareUrlForInvoicePage = (id: string): string => {
  return `${ENV.SQUARE_DASHBOARD_URL}/invoices/${id}`
}

export const squareUrlForCustomerPage = (id: string): string => {
  return `${ENV.SQUARE_DASHBOARD_URL}/customers/directory/customer/${id}`
}

export const squareUrlForCreateNewInvoicePage = (id: string): string => {
  return `${ENV.SQUARE_DASHBOARD_URL}/invoices/new?contactToken=${id}`
}

export const stripeUrlForCustomerPage = (id: string): string => {
  return `${ENV.STRIPE_DASHBOARD_URL}/customers/${id}`
}

export const stripeUrlForInvoicePage = (id: string): string => {
  return `${ENV.STRIPE_DASHBOARD_URL}/invoices/${id}`
}

export const stripeUrlForTransactionPage = (id: string): string => {
  return `${ENV.STRIPE_DASHBOARD_URL}/payments/${id}`
}

export const getTransactionIdFromSquareUrl = (url: string): string | null => {
  return getIdFromExternalUrl(url, '/transactions/')
}

export const getCallIdFromAircallUrl = (url: string): string | null => {
  return getIdFromExternalUrl(url, '/calls/')
}

const getIdFromExternalUrl = (
  url: string,
  precedingCharacters: string
): string | null => {
  const regexPattern = `${precedingCharacters}([^/]+)`
  const matches = url.match(new RegExp(regexPattern))
  return matches ? matches[1] : null
}

export const aircallUrlFromCallId = (id: string): string => {
  return `https://assets.aircall.io/calls/${id}/recording`
}

export const convertPoundsToPence = (value: number | null): number | null => {
  if (value === null) return null
  const pounds = ensureNumberOrNull(value)
  return typeof pounds === 'number' ? Math.round(pounds * 100) : null
}

type ValueAndLabel = {
  value: string
  label: string
}

export const getLabel = (
  options: ValueAndLabel[],
  value: string | null
): string | undefined => {
  return options.find((option) => option.value === value)?.label
}

export const formatAddress = (address: Address, separator = ', '): string =>
  [
    address.lineOne,
    address.lineTwo,
    address.city,
    address.postalCode,
    address.countryCode,
  ]
    .filter(identity)
    .join(separator)

/**
 * In Formik, when there is no value, it will be presented as empty string,
 * this can cause problems while passing it to the backend API, as some of them
 * expect different type than string, e.g. number, boolean, etc.
 */
export const formatEmptyStringToUndefined = <T>(value: T) =>
  typeof value === 'string' && value === '' ? undefined : value

export const getFrontLink = (email?: string | null) => {
  const query = email
  return query
    ? `https://app.frontapp.com/inboxes/teams/4610822/inbox/unassigned/0/search/global/${encodeURI(
        query
      )}`
    : ''
}

export const getZendeskUser = (externalZendeskId: string) =>
  `https://farewill.zendesk.com/agent/users/${externalZendeskId}`

export const getYearsElapsed = ({
  dateOfBirth,
  dateOfDeath,
}: {
  dateOfBirth: string
  dateOfDeath?: string
}) => {
  const endDate = dateOfDeath ? dateOfDeath : new Date()
  return moment(endDate).diff(moment(dateOfBirth), 'years')
}

export const pluralise = (count: number, noun: string, suffix = 's') => {
  if (count > 1) {
    return `${noun}${suffix}`
  }
  return noun
}

export const isUrl = (url: string): boolean => {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}
