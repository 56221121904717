import { useState } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import { Button, Grid, H, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import InputFloating from 'components/form/input-floating'
import Table from 'components/table'
import { isUrl } from 'utils/helpers'

const StyledInputFloating = styled(InputFloating)`
  border-radius: ${GTR.XXS} 0 0 ${GTR.XXS};
`
const StyledAddButton = styled(Button.Secondary)`
  border-radius: 0 ${GTR.XXS} ${GTR.XXS} 0;
  height: 100%;
`
const StyledTableRow = styled(Table.Row)`
  padding: 0;
`
const StyledGrid = styled(Grid)`
  padding: 0 ${GTR.S};
`
const StyledGridItem = styled(Grid.Item)`
  word-wrap: break-word;
`
const StyledRemoveButton = styled(Button.Underline)`
  padding: 0;
`

const AiMarketingAssistantSetup = (): React.ReactElement => {
  const [error, setError] = useState<string | null>(null)
  const { values, setFieldValue } = useFormikContext<{
    rawUrlsString: string
    urls: string[]
  }>()

  const addUrls = (rawUrlsString: string | null): void => {
    if (!rawUrlsString) return

    const newUrls = rawUrlsString
      .split(',')
      .map((url) => url.trim())
      .filter((url) => url.length > 0)

    const invalidUrls = newUrls.filter((url) => !isUrl(url))

    if (invalidUrls.length > 0) {
      setError(
        `The following ${
          invalidUrls.length > 1 ? 'are not valid URLs' : 'is not a valid URL'
        }: ${invalidUrls.join(', ')}`
      )
      return
    }

    setFieldValue(
      'urls',
      Array.from(new Set([...(values.urls || []), ...newUrls]))
    )
    setFieldValue('rawUrlsString', '') // set it to an empty string to clear the input
  }

  const removeUrl = (unwantedUrl: string): void => {
    setFieldValue(
      'urls',
      values.urls.filter((url) => url !== unwantedUrl)
    )
  }

  return (
    <Wrapper margin={['M', 0]}>
      <H size="S">AI Marketing Assistant setup</H>
      <P>
        Add links to the partner’s existing marketing material so our AI bot can
        learn from them and generate relevant marketing copy for them.
      </P>
      <P>
        Add urls one by one, or if you have loads, add multiple in a row
        separated by a comma. For example, [url-1],[url-2],[url-3].
      </P>

      {/* Form to input the URLs */}
      <Grid gap={0}>
        <Grid.Item span={11} onFocus={() => setError(null)}>
          <StyledInputFloating name="rawUrlsString" label="Partner URLs" />
        </Grid.Item>
        <Grid.Item span={1}>
          <StyledAddButton
            type="button"
            onClick={() => {
              addUrls(values.rawUrlsString)
            }}
          >
            Add
          </StyledAddButton>
        </Grid.Item>
      </Grid>
      {error && <P color={COLOR.STATE.ERROR}>{error}</P>}

      {/* Table displaying the saved urls */}
      {values.urls?.length > 0 && (
        <Wrapper margin={['M', 0]}>
          <H size="XS">Partner URLs</H>
          <Table striped>
            {values.urls.map((url) => {
              return (
                <StyledTableRow key={url} gridTemplateCols="6fr">
                  <StyledGrid gap={0}>
                    <StyledGridItem span={11}>{url}</StyledGridItem>
                    <Grid.Item span={1}>
                      <StyledRemoveButton
                        type="button"
                        size="S"
                        onClick={() => {
                          removeUrl(url)
                        }}
                      >
                        Remove
                      </StyledRemoveButton>
                    </Grid.Item>
                  </StyledGrid>
                </StyledTableRow>
              )
            })}
          </Table>
        </Wrapper>
      )}
    </Wrapper>
  )
}

export default AiMarketingAssistantSetup
