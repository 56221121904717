import * as PersonalQuestions from './questions/personal-questions'
import * as WishesQuestions from './questions/wishes'
import * as OptionalQuestions from './questions/optional'
import * as LargePrintQuestions from './questions/large-print'
import { LARGE_PRINT, OPTIONAL, PERSONAL_AND_ASSETS, WISHES } from './constants'
import { QuestionConfig } from './types'

export const QUESTIONS = {
  [PERSONAL_AND_ASSETS]: [
    PersonalQuestions.RelationshipStatus,
    PersonalQuestions.PartnerWantsWill,
    PersonalQuestions.PartnerFirstName,
    PersonalQuestions.PartnerLastName,
    PersonalQuestions.PartnerPhoneNumber,
    PersonalQuestions.PartnerEmail,
    PersonalQuestions.PartnerAddress,
    PersonalQuestions.PartnerDateOfBirth,
    PersonalQuestions.Residence,
    PersonalQuestions.ResidenceIsPermanentAddress,
    PersonalQuestions.PermanentResidence,
    PersonalQuestions.EverythingInUK,
    PersonalQuestions.StepRelations,
    PersonalQuestions.HighValueAssets,
    PersonalQuestions.OwnsFarm,
  ],
  [WISHES]: [
    WishesQuestions.BusinessGift,
    WishesQuestions.PropertyGift,
    WishesQuestions.DisabledOrMeansTestedBeneficiary,
  ],
  [OPTIONAL]: [
    OptionalQuestions.HouseInTrust,
    OptionalQuestions.BeneficiaryInvolvedInDivorceOrBankruptcy,
  ],
  [LARGE_PRINT]: [LargePrintQuestions.LargePrint],
} as Record<string, QuestionConfig[]>
