import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isBoolean } from 'lodash'
import axiosRequest from 'lib/axios/api-request'

import { Form, Formik, FormikHelpers } from 'formik'
import { PARTNER_SCHEMA } from 'lib/formik/schemata'

import Overview from 'routes/partners/partner-detail/overview'
import UpdateOrCreateForm from 'routes/partners/components/form/partner-form'
import Review from 'routes/partners/components/form/review'
import { PartnerSummary } from 'lib/models/partner'
import useApi from 'lib/effects/api'
import { GetApiResponse, Values } from 'routes/partners/interfaces'

import { constructPartnerFormData } from '../helpers'
import { SentryRoute } from 'instrument'

const PartnerDetail = (): React.ReactElement => {
  const { id: partnerId } = useParams<{ id: string }>()
  const [partner, setPartner] = useState<PartnerSummary | undefined>(undefined)
  const [formValues, setFormValues] = useState<Values>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [, fetchPartner] = useApi({ data: [] })

  async function loadPartner() {
    setIsLoading(true)
    const { data } = await fetchPartner<GetApiResponse>({
      url: `/api/partners/${partnerId}`,
    })
    setPartner(data.attributes)
    const formValues = {
      logo: null,
      giftPromptImage: null,
      type: data.attributes.charity ? 'charity' : 'organisation',
      partnerDataSharing: data.attributes.dataSharingReportType
        ? 'enabled'
        : 'disabled',
      ...data.attributes,
      organisation: {
        ...data.attributes.organisation,
        /** We need to convert the boolean `hasExemptCharitableStatus` to
         * a StringBoolean to populate the Formik field */
        hasExemptCharitableStatus: isBoolean(
          data.attributes.organisation?.hasExemptCharitableStatus
        )
          ? data.attributes.organisation?.hasExemptCharitableStatus.toString()
          : '',
      } as Values['organisation'],
    }
    setFormValues({
      ...formValues,
      // This is used for the radio toggle and validation purposes
      isGiftPrompt: !!(
        formValues.giftPromptDescription && formValues.giftPromptImageUrl
      ),
      rawUrlsString: null,
    })
    setIsLoading(false)
  }

  useEffect(() => {
    loadPartner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPartner, partnerId])

  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const history = useHistory()

  const handleSubmit = async (
    values: Values,
    helpers: FormikHelpers<Values>
  ) => {
    const formData = constructPartnerFormData(values, partnerId)

    await axiosRequest({
      url: `/api/partners/${partnerId}`,
      method: 'PATCH',
      data: formData,
    })
    await loadPartner()
    history.push(`/partners/${partnerId}`)
    helpers.resetForm()
  }

  return (
    <Formik
      initialValues={formValues as Values}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={PARTNER_SCHEMA}
    >
      <Form>
        <SentryRoute
          path="/partners/:id"
          exact
          render={(_props) => (
            <Overview partner={partner} isLoading={isLoading} />
          )}
        />
        <SentryRoute
          path="/partners/:id/update"
          exact
          render={() => (
            <UpdateOrCreateForm
              operation="update"
              showErrorMessage={showErrorMessage}
              setShowErrorMessage={setShowErrorMessage}
              isLoading={isLoading}
            />
          )}
        />
        <SentryRoute
          path="/partners/:id/review"
          render={() => (
            <Review
              partnerId={partnerId}
              isLoading={isLoading}
              operation="update"
            />
          )}
        />
      </Form>
    </Formik>
  )
}
export default PartnerDetail
