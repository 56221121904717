import { CharityAttributes as Charity } from './charity'
import { OrganisationAttributes as Organisation } from './organisation'
import { PartnerAccount } from './partner-account'

// TODO: Fetch these from the backend so we don't duplicate them here.
export const PARTNER_CAUSE_AREA_OPTIONS = [
  { label: 'Animal welfare', value: 'animal_welfare' },
  { label: 'Arts culture and museums', value: 'arts_culture_and_museums' },
  { label: 'Bereavement', value: 'bereavement' },
  { label: 'Campaigning', value: 'campaigning' },
  { label: 'Cancer', value: 'cancer' },
  { label: 'Children and young people', value: 'children_and_young_people' },
  { label: 'Community and support', value: 'community_and_support' },
  { label: 'Conservation and heritage', value: 'conservation_and_heritage' },
  { label: 'Culture', value: 'culture' },
  { label: 'Disabled people and access', value: 'disabled_people_and_access' },
  { label: 'Education', value: 'education' },
  { label: 'Elderly people', value: 'elderly_people' },
  {
    label: 'Emergency response and rescue',
    value: 'emergency_response_and_rescue',
  },
  {
    label: 'Environment and sustainability',
    value: 'environment_and_sustainability',
  },
  { label: 'Faith based', value: 'faith_based' },
  { label: 'Health', value: 'health' },
  { label: 'Health research', value: 'health_research' },
  { label: 'Homelessness and housing', value: 'homelessness_and_housing' },
  { label: 'Hospice', value: 'hospice' },
  { label: 'Hospital', value: 'hospital' },
  { label: 'Human rights', value: 'human_rights' },
  { label: 'Humanitarian aid', value: 'humanitarian_aid' },
  { label: 'Mental health', value: 'mental_health' },
  { label: 'Military', value: 'military' },
  { label: 'Physical health', value: 'physical_health' },
  { label: 'Policy and research', value: 'policy_and_research' },
  { label: 'Sports and recreation', value: 'sports_and_recreation' },
] as const
export const CAUSE_AREAS = PARTNER_CAUSE_AREA_OPTIONS.map(({ value }) => value)

export const reportTypes = [
  'voucher_code_users',
  'voucher_code_users_opt_in_marketing',
  'voucher_code_users_with_gifts',
] as const
export type ReportType = (typeof reportTypes)[number]

// Used in the form
export const DATA_SHARING_REPORT_TYPES_OPTIONS = [
  {
    label: 'All customers who used their voucher code',
    value: 'voucher_code_users',
  },
  {
    label: 'Only customers that have also opted in to marketing',
    value: 'voucher_code_users_opt_in_marketing',
  },
  {
    label: 'Only customers that have also left a gift',
    value: 'voucher_code_users_with_gifts',
  },
] as const
// Used in the summary page
export const DATA_SHARING_REPORT_TYPES_EXPLANATIONS = [
  {
    label:
      'Share monthly reports with details of all customers who use a voucher code.',
    value: 'voucher_code_users',
  },
  {
    label:
      'Share monthly reports with details of all customers who use a voucher code and opt in for marketing.',
    value: 'voucher_code_users_opt_in_marketing',
  },
  {
    label:
      'Share monthly reports with details of all customers who use a voucher code and leave a gift.',
    value: 'voucher_code_users_with_gifts',
  },
  { label: 'Do not generate monthly reports', value: null },
] as const

export type Partner = {
  type: string
  attributes: {
    id: string
    address?: string
    causeArea?: string
    disabled: boolean
    hasExemptCharitableStatus?: boolean
    hubspotUrl?: string
    logo?: Blob | MediaSource // this is the raw image logo to be sent to the API and converted in to a URL
    name: string
    number?: string
    type: string
    utmSource: string
    dataSharingReportType?: string
    featuredIndex?: number
    products: Product[]
  }
}

export enum CONFIGURATION {
  A11Y_CHECKS = 'accessibilityChecks',
  MAILCHIMP_BESPOKE = 'mailchimpBespokeJourney',
}
export type Product = {
  product: PARTNER_PRODUCTS
  configuration: CONFIGURATION[]
}

export enum PARTNER_PRODUCTS {
  ONLINE_WILL = 'online_will',
  TELEPHONE_WILL = 'telephone_will',
}

export const PARTNER_PRODUCTS_OPTIONS = [
  { value: 'online_will', label: '💻 Online wills' },
  { value: 'telephone_will', label: '📞 Telephone wills' },
]

export const AI_BOT_STATUS = [
  { value: 'enabled', label: 'Enabled' },
  { value: 'disabled', label: 'Disabled' },
]

export const PARTNER_TYPES_OPTIONS = [
  { value: 'organisation', label: 'Organisation' },
  { value: 'charity', label: 'Charity' },
]

type CharityColumns = 'commonName' | 'legalName' | 'number'
type OrganisationColumns =
  | 'name'
  | 'address'
  | 'number'
  | 'hasExemptCharitableStatus'
export type PartnerSummary = {
  utmSource: string
  causeArea: (typeof CAUSE_AREAS)[number] | null
  logoUrl: string
  hubspotUrl: string | null
  charity: Pick<Charity, CharityColumns> | null
  organisation: Pick<Organisation, OrganisationColumns> | null
  dataSharingReportType: ReportType | null
  products: PARTNER_PRODUCTS[]
  onlineWillConfiguration: CONFIGURATION[]
  giftPromptDescription: string
  giftPromptImageUrl: string
  accounts: PartnerAccount[]
  urls: string[]
  /**
   * Not available during partner creation
   */
  id?: string
}
